import { Api } from '../settings';
import { getUtcDate } from '../utils';
import useFeatureFlags from '../utils/useFeatureFlags';
import useAuthorisedQuery from './useAuthorisedQuery';

const useDataStatusQuery = (startDate: Date, endDate: Date) => {
  const featureFlags = useFeatureFlags();
  // Yes, officer, that's the line of code right there.
  const path = featureFlags.includes('useNextDataStatus') ? 'data-status-next' : 'data-status';
  const startDateValue = getUtcDate(startDate);
  const endDateValue = getUtcDate(endDate);
  return useAuthorisedQuery(
    [path],
    `${path}/?start_datetime=${startDateValue}&end_datetime=${endDateValue}`,
    {},
    Api.serve
  );
};

export default useDataStatusQuery;
