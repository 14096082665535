import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { createUseStyles } from 'react-jss';
import { ChartData } from './useChartData';
import { EzerTheme, useEzerTheme } from '../../../EzerThemeProvider';
import { useTicksByDate, getLocalDate, useTimezone } from '../../../utils';
import useYTicks from './useYTicks';
import { LabelledContainer, LocalisedLabel } from '../../../lib';
import CartesianGrid from '../../../lib/CartesianGrid';
import TooltipContent from './TooltipContent';
import ToggleLegend from './ToggleLegend/ToggleLegend';
import { ChartConfig } from '../schema';
import useLegendToggledItems from './ToggleLegend/useLegendToggledItems';

type Props = {
  data: ChartData;
  startDate: Date;
  endDate: Date;
  chartConfigs: ChartConfig[];
};

const useStyles = createUseStyles(({ spacing }: EzerTheme) => ({
  root: {
    marginTop: spacing(2)
  },
  container: {
    position: 'relative'
  }
}));

const Chart = ({ data, startDate, endDate, chartConfigs }: Props) => {
  const styles = useStyles();
  const { palette, spacing } = useEzerTheme();
  const { xAxisLabel, yAxisLabel, rangeStart: leftRangeStart, rangeEnd: leftRangeEnd } = chartConfigs[0];
  const { rangeStart: rightRangeStart, rangeEnd: rightRangeEnd } = chartConfigs[chartConfigs.length - 1];
  const colours = [palette.leaf, palette.white, palette.lava, palette.flame, palette.glow, palette.electric];
  const options = chartConfigs.map(({ featureName, title }, index) => ({
    value: featureName as string,
    description: <LocalisedLabel>{title}</LocalisedLabel>,
    color: colours[index] ?? 'white'
  }));
  const legendToggleItems = useLegendToggledItems();

  const xTicks = useTicksByDate(startDate, endDate);

  const showDecimalPoints = leftRangeEnd ? leftRangeEnd <= 1000 : true;
  const timezone = useTimezone();
  const leftYTicks = useYTicks(leftRangeStart, leftRangeEnd);
  const leftDomain =
    leftYTicks && leftYTicks.length > 0 ? [leftYTicks[0], leftYTicks[leftYTicks.length - 1]] : undefined;
  const rightYTicks = useYTicks(rightRangeStart, rightRangeEnd);
  const rightDomain =
    rightYTicks && rightYTicks.length > 0 ? [rightYTicks[0], rightYTicks[rightYTicks.length - 1]] : undefined;
  const unit = '%';
  return (
    <LabelledContainer yAxisLabel={yAxisLabel} xAxisLabel={xAxisLabel} unit={unit}>
      <ResponsiveContainer width="100%" height={spacing(58)} className={styles.root}>
        <LineChart margin={{ top: spacing(1), right: 0, bottom: 0, left: 0 }}>
          {ToggleLegend({ options })}
          <CartesianGrid />
          {chartConfigs.map((chartConfig, index) => (
            <Line
              type="linear"
              data={data[chartConfig.featureName]}
              dataKey="value"
              stroke={colours[index] ?? 'white'}
              strokeWidth={2}
              isAnimationActive={false}
              dot={false}
              hide={!legendToggleItems.includes(chartConfig.featureName)}
              connectNulls
              yAxisId={index <= chartConfigs.length - 3 ? 'left-axis' : 'right-axis'}
            />
          ))}

          <XAxis
            dataKey="date"
            type="number"
            domain={[startDate.getTime(), endDate.getTime()]}
            ticks={xTicks}
            tickFormatter={(tick: string) => getLocalDate(new Date(tick), timezone)}
            allowDataOverflow
            height={spacing(4)}
          />
          <YAxis
            width={spacing(6)}
            dataKey={chartConfigs[0].featureName}
            ticks={leftYTicks}
            domain={leftDomain}
            allowDataOverflow
            tickFormatter={(tick: number) => tick.toFixed(showDecimalPoints ? 1 : 0)}
            yAxisId="left-axis"
          />
          <YAxis
            width={spacing(6)}
            dataKey={chartConfigs[chartConfigs.length - 1].featureName}
            ticks={rightYTicks}
            domain={rightDomain}
            allowDataOverflow
            tickFormatter={(tick: number) => tick.toFixed(showDecimalPoints ? 1 : 0)}
            yAxisId="right-axis"
            orientation="right"
          />
          {data && <Tooltip content={<TooltipContent unit={unit} />} />}
        </LineChart>
      </ResponsiveContainer>
    </LabelledContainer>
  );
};

export default Chart;
