import { Legend } from 'recharts';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { useEzerTheme } from '../../../../EzerThemeProvider';
import LegendItem from './LegendItem';
import useLegendToggledItems, { KEY } from './useLegendToggledItems';

export type ToggleOption = {
  value: string;
  description: React.ReactNode;
  color: string;
};

type Props = {
  options: ToggleOption[];
};
const ToggleLegend = ({ options }: Props) => {
  const { spacing } = useEzerTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const legendToggledItems = useLegendToggledItems();
  const onClick = ({ value }: Record<'value', string>) => {
    if (legendToggledItems.includes(value)) {
      if (legendToggledItems.length > 1) {
        const index = legendToggledItems.indexOf(value);
        legendToggledItems.splice(index, 1);
      }
    } else {
      legendToggledItems.push(value);
    }

    searchParams.set(KEY, legendToggledItems.join(','));
    setSearchParams(searchParams);
  };

  const formatter = (value: string) => {
    const thisOption = options.find((option) => option.value === value);
    return <LegendItem value={value}>{thisOption?.description ?? <>{value}</>}</LegendItem>;
  };

  const payload = options.map(({ value, color }) => ({
    value,
    color,
    type: 'line'
  }));
  // @ts-ignore
  return <Legend verticalAlign="top" onClick={onClick} height={spacing(4)} formatter={formatter} payload={payload} />;
};

export default ToggleLegend;
