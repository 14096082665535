import { useSearchParams } from 'react-router-dom';

export const KEY = 'toggledLegendItems';

type LegendItems = string[];
const useLegendToggledItems = (): LegendItems => {
  const [searchParams] = useSearchParams();
  const value = searchParams?.get(KEY);
  if (value) {
    return value.split(',') as LegendItems;
  }
  return [];
};

export default useLegendToggledItems;
