import { addHours, subHours } from 'date-fns';
import { TemplateSchema } from './schema';
import { DataLoadErrorMessage, LoadingMessage, LocalisedLabel, PageTitle } from '../../lib';
import { useFileName, useHistoricalSoftSensors } from '../../utils';
import useNow from '../../useNow';
import useStartDate from '../../FilterControls/useStartDate';
import useEndDate from '../../FilterControls/useEndDate';
import FilterControls from '../../FilterControls/FilterControls';
import Table from './Table/Table';
import ChartContainer from './ChartContainer/ChartContainer';

const HistoricalPerformanceImprovementPage = ({ label, options: { chartConfigs } }: TemplateSchema) => {
  const now = useNow();
  const startDate = useStartDate(subHours(now, 24));
  const endDate = useEndDate(addHours(now, 6));

  const featureNames = chartConfigs.length > 0 ? chartConfigs.map((config) => config.featureName) : [];

  const { data, isLoading, isError } = useHistoricalSoftSensors(featureNames, startDate, endDate);

  const fileName = useFileName(label, startDate, endDate);

  return (
    <>
      <PageTitle>
        <LocalisedLabel>{label}</LocalisedLabel>
      </PageTitle>
      <FilterControls isLoading={isLoading} showFutureWindow />

      {isLoading && <LoadingMessage />}
      {isError && <DataLoadErrorMessage />}

      {!isLoading && !isError && startDate && endDate && chartConfigs.length >= 1 && (
        <>
          {chartConfigs.length > 0 && (
            <ChartContainer data={data} startDate={startDate} endDate={endDate} chartConfigs={chartConfigs} />
          )}
          <Table chartConfigs={chartConfigs} data={data} fileName={fileName} />
        </>
      )}
    </>
  );
};

export default HistoricalPerformanceImprovementPage;
