import { ReferenceAreaData } from '../types';

const filterDateByRange = (date: number | undefined, startRange: Date, endRange: Date): number => {
  const startRangeTime = startRange.getTime();
  const endRangeTime = endRange.getTime();
  if (date === undefined) {
    return endRangeTime;
  }
  if (date < startRangeTime) {
    return startRangeTime;
  }
  if (date > endRangeTime) {
    return endRangeTime;
  }
  return date;
};

const useRangedReferenceAreaData = (referenceAreaData: ReferenceAreaData, startRange: Date, endRange: Date) =>
  referenceAreaData.map((datum) => ({
    start: filterDateByRange(datum.start, startRange, endRange),
    end: filterDateByRange(datum.end, startRange, endRange)
  }));

export default useRangedReferenceAreaData;
