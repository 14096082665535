import React from 'react';
import { createUseStyles } from 'react-jss';
import { Legend, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import classNames from 'classnames';
import { GasAnalyserData } from '../useGasAnalyserData';
import { EzerTheme, useEzerTheme } from '../../../../EzerThemeProvider';
import {
  getLocalDate,
  useHighGasAnalyserData,
  useInterpolatedData,
  useLowGasAnalyserData,
  useTicksByDate,
  useTimezone
} from '../../../../utils';
import { AxisTitle, CartesianGrid, LocalisedLabel } from '../../../../lib';
import useLegendPayload from './useLegendPayload';
import TooltipContent from './TooltipContent';
import useYTicks from './useYTicks';
import { Options } from '../../schema';
import { Date as DateMessage } from '../../../../messages';

type Props = {
  data: GasAnalyserData;
  startDate: Date;
  endDate: Date;
  options: Options;
  leftUnit: string;
  rightUnit: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    position: 'relative',
    paddingRight: spacing(3),
    paddingLeft: spacing(0.5),
    marginTop: spacing(2)
  },
  label: {
    fontSize: spacing(2.5),
    color: palette.white,
    textAlign: 'center',
    bottom: spacing(2)
  },
  rightLabel: {
    top: 0,
    right: 0,
    transform: 'rotate(180deg)',
    position: 'absolute',
    writingMode: 'vertical-lr'
  },
  leftLabel: {
    top: 0,
    left: 0,
    transform: 'rotate(180deg)',
    position: 'absolute',
    writingMode: 'vertical-lr'
  },
  referenceArea: {
    opacity: 0.5
  }
}));

const Chart = ({ data, startDate, endDate, options, leftUnit, rightUnit }: Props) => {
  const styles = useStyles();
  const { spacing, palette } = useEzerTheme();
  const {
    leftFeature,
    rightFeature,
    performanceFeature,
    rightRangeStart,
    rightRangeEnd,
    leftRangeStart,
    leftRangeEnd
  } = options;
  const timezone = useTimezone();
  const xTicks = useTicksByDate(startDate, endDate);

  const leftData = useInterpolatedData(data.map((item) => ({ date: item.date, value: item.leftValue })));
  const rightData = useInterpolatedData(
    data.map((item) => ({
      date: item.date,
      value: item.rightValue
    }))
  );

  const lowData = useLowGasAnalyserData(data, startDate, endDate);
  const highData = useHighGasAnalyserData(data, startDate, endDate);

  const legendPayload = useLegendPayload(leftFeature, rightFeature, performanceFeature, leftUnit, rightUnit);

  const yTicksLeft = useYTicks(leftRangeStart, leftRangeEnd);
  const yTicksRight = useYTicks(rightRangeStart, rightRangeEnd);

  const tooltipContent = (
    <TooltipContent leftFeature={leftFeature} rightFeature={rightFeature} leftUnit={leftUnit} rightUnit={rightUnit} />
  );

  return (
    <div className={styles.root}>
      <p className={classNames(styles.label, styles.leftLabel)}>
        <LocalisedLabel>{leftFeature.title}</LocalisedLabel> ({leftUnit})
      </p>
      <p className={classNames(styles.label, styles.rightLabel)}>
        <LocalisedLabel>{rightFeature.title}</LocalisedLabel> ({rightUnit})
      </p>
      <ResponsiveContainer width="100%" height={spacing(58)}>
        <LineChart margin={{ top: spacing(1), right: 0, bottom: 0, left: 0 }} data={data}>
          <Legend verticalAlign="top" height={36} payload={legendPayload} />
          <CartesianGrid />
          <Line
            type="linear"
            dataKey="value"
            stroke={palette.leaf}
            strokeWidth={1}
            isAnimationActive={false}
            dot={false}
            yAxisId="left-axis"
            data={leftData}
          />
          {lowData.map(({ start, end }) => (
            <ReferenceArea
              key={start}
              x1={start}
              x2={end}
              fill={palette.lava}
              className={styles.referenceArea}
              yAxisId="left-axis"
            />
          ))}
          <Line
            type="linear"
            dataKey="value"
            stroke={palette.white}
            strokeWidth={1}
            isAnimationActive={false}
            dot={false}
            yAxisId="right-axis"
            data={rightData}
          />
          {highData.map(({ start, end }) => (
            <ReferenceArea
              key={start}
              x1={start}
              x2={end}
              fill={palette.glow}
              className={styles.referenceArea}
              yAxisId="left-axis"
            />
          ))}
          <XAxis
            dataKey="date"
            type="number"
            domain={[startDate.getTime(), endDate.getTime()]}
            ticks={xTicks}
            tickFormatter={(tick: string) => getLocalDate(new Date(tick), timezone)}
            allowDataOverflow
            height={spacing(4)}
            allowDuplicatedCategory={false}
          />
          <YAxis
            width={spacing(8)}
            dataKey="value"
            ticks={yTicksLeft}
            domain={yTicksLeft === undefined ? undefined : [yTicksLeft[0], yTicksLeft[yTicksLeft.length - 1]]}
            allowDataOverflow
            tickFormatter={(tick: number) => tick.toFixed(1)}
            yAxisId="left-axis"
          />
          <YAxis
            width={spacing(8)}
            dataKey="value"
            ticks={yTicksRight}
            domain={yTicksRight === undefined ? undefined : [yTicksRight[0], yTicksRight[yTicksRight.length - 1]]}
            allowDataOverflow
            tickFormatter={(tick: number) => `${(tick / 1000).toFixed(1)}K`}
            yAxisId="right-axis"
            orientation="right"
          />
          {data.length > 0 && <Tooltip content={tooltipContent} />}
        </LineChart>
      </ResponsiveContainer>
      <AxisTitle title={<DateMessage />} />
    </div>
  );
};

export default Chart;
