import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import React from 'react';
import { isBefore } from 'date-fns';
import { createSearchParams, generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { Legend, Line, LineChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { EzerTheme, useEzerTheme } from '../../../../EzerThemeProvider';
import {
  getLocalTime,
  useTimezone,
  useLowGasAnalyserData,
  useHighGasAnalyserData,
  useInterpolatedData
} from '../../../../utils';
import { useSevenTicks } from '../../utils';
import { CartesianGrid, LocalisedLabel } from '../../../../lib';
import TooltipContent from './TooltipContent';
import { GasAnalyserData } from './useGasAnalyserData';
import useYTicks from './useYTicks';
import useLegendPayload from './useLegendPayload';
import { GasAnalyserChartConfig } from '../../schema';

type Props = {
  startDate: Date;
  endDate: Date;
  config: GasAnalyserChartConfig;
  data: GasAnalyserData;
  leftUnit: string;
  rightUnit: string;
};

const useStyles = createUseStyles(({ spacing, palette }: EzerTheme) => ({
  root: {
    position: 'relative',
    paddingRight: spacing(3),
    paddingLeft: spacing(0.5)
  },
  label: {
    fontSize: spacing(2),
    color: palette.white,
    textAlign: 'center',
    transform: 'rotate(180deg)',
    top: 0,
    bottom: spacing(2),

    position: 'absolute',
    writingMode: 'vertical-lr'
  },
  leftLabel: {
    left: 0
  },
  rightLabel: {
    right: 0
  },
  referenceArea: {
    opacity: 0.5,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7
    }
  }
}));

const GasAnalyserChart = ({ startDate, endDate, config, data, leftUnit, rightUnit }: Props) => {
  const styles = useStyles();
  const { spacing, palette } = useEzerTheme();
  const timezone = useTimezone();
  const xTicks = useSevenTicks(startDate, endDate);
  const yTicksLeft = useYTicks(config.leftFeatureConfig);
  const yTicksRight = useYTicks(config.rightFeatureConfig);
  const lowData = useLowGasAnalyserData(data, startDate, endDate);
  const highData = useHighGasAnalyserData(data, startDate, endDate);
  const legendPayload = useLegendPayload(config, leftUnit, rightUnit);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const leftData = useInterpolatedData(data.map((item) => ({ date: item.date, value: item.leftValue })));
  const rightData = useInterpolatedData(
    data.map((item) => ({
      date: item.date,
      value: item.rightValue
    }))
  );

  const onClickReferenceArea = (end: number | undefined, start: number) => {
    const areaStartDate = new Date(start);
    const areaEndDate = end !== undefined ? new Date(end) : new Date();
    if (config.to) {
      if (isBefore(areaStartDate, areaEndDate)) {
        searchParams.set('startDate', areaStartDate.toISOString());
        searchParams.set('endDate', areaEndDate.toISOString());
      } else {
        searchParams.set('startDate', areaEndDate.toISOString());
        searchParams.set('endDate', areaStartDate.toISOString());
      }
      const path = generatePath(':url?:queryString', {
        url: config.to,
        queryString: createSearchParams(searchParams).toString()
      });
      navigate(path);
    }
  };

  return (
    <div className={styles.root}>
      <p className={classNames(styles.label, styles.leftLabel)}>
        <LocalisedLabel>{config.leftFeatureConfig.feature.label}</LocalisedLabel> ({leftUnit})
      </p>
      <p className={classNames(styles.label, styles.rightLabel)}>
        <LocalisedLabel>{config.rightFeatureConfig.feature.label}</LocalisedLabel> ({rightUnit})
      </p>
      <ResponsiveContainer width="100%" height={spacing(38)}>
        <LineChart margin={{ top: spacing(1), right: 0, bottom: 0, left: 0 }} data={data}>
          <Legend verticalAlign="top" height={36} payload={legendPayload} />
          <CartesianGrid />
          <Line
            type="linear"
            dataKey="value"
            stroke={palette.leaf}
            strokeWidth={1}
            isAnimationActive={false}
            dot={false}
            yAxisId="left-axis"
            data={leftData}
          />
          {lowData.map(({ start, end }) => (
            <ReferenceArea
              key={start}
              x1={start}
              x2={end}
              fill={palette.lava}
              className={styles.referenceArea}
              yAxisId="left-axis"
              onClick={() => onClickReferenceArea(end, start)}
            />
          ))}
          <Line
            type="linear"
            dataKey="value"
            stroke={palette.white}
            strokeWidth={1}
            isAnimationActive={false}
            dot={false}
            yAxisId="right-axis"
            data={rightData}
          />
          {highData.map(({ start, end }) => (
            <ReferenceArea
              key={start}
              x1={start}
              x2={end}
              fill={palette.glow}
              className={styles.referenceArea}
              yAxisId="left-axis"
              onClick={() => onClickReferenceArea(end, start)}
            />
          ))}
          <XAxis
            dataKey="date"
            type="number"
            domain={[startDate.getTime(), endDate.getTime()]}
            ticks={xTicks}
            tickFormatter={(tick: string) => getLocalTime(new Date(tick), timezone)}
            allowDataOverflow
            height={spacing(4)}
            allowDuplicatedCategory={false}
          />
          <YAxis
            width={spacing(6)}
            dataKey="leftValue"
            ticks={yTicksLeft}
            domain={yTicksLeft === undefined ? undefined : [yTicksLeft[0], yTicksLeft[yTicksLeft.length - 1]]}
            allowDataOverflow
            tickFormatter={(tick: number) => tick.toFixed(1)}
            yAxisId="left-axis"
          />
          <YAxis
            width={spacing(6)}
            dataKey="rightValue"
            ticks={yTicksRight}
            domain={yTicksRight === undefined ? undefined : [yTicksRight[0], yTicksRight[yTicksRight.length - 1]]}
            allowDataOverflow
            tickFormatter={(tick: number) => `${(tick / 1000).toFixed(1)}K`}
            yAxisId="right-axis"
            orientation="right"
          />
          {data.length > 0 && (
            <Tooltip content={<TooltipContent config={config} leftUnit={leftUnit} rightUnit={rightUnit} />} />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GasAnalyserChart;
