import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import { FeatureName } from '../../../types';

type Payload = {
  date: number;
  value?: number;
};

export type ChartData = Record<string, Payload[]>;

const useChartData = (data: SoftSensor[], featureNames: FeatureName[]): ChartData => {
  const result = {};

  featureNames.forEach((featureName: FeatureName) => {
    // @ts-ignore
    result[featureName] = data
      .filter((datum) => datum.feature === featureName)
      .map((datum) => ({
        date: new Date(datum.timestamp).getTime(),
        value: datum.value
      }));
  });
  return result;
};

export default useChartData;
