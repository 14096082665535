import { SoftSensor } from '../../../utils/useHistoricalSoftSensors';
import Chart from './Chart';
import { ChartConfig } from '../schema';
import useChartData from './useChartData';

type Props = {
  data: SoftSensor[];
  startDate: Date;
  endDate: Date;
  chartConfigs: ChartConfig[];
};
const ChartContainer = ({ data, startDate, endDate, chartConfigs }: Props) => {
  const featureNames = chartConfigs.map(({ featureName }) => featureName);

  const chartData = useChartData(data, featureNames);

  return <Chart data={chartData} startDate={startDate} endDate={endDate} chartConfigs={chartConfigs} />;
};

export default ChartContainer;
